import { IconProps } from "./IconProps";
import { FC } from "react";

export const GlobalIcon: FC<IconProps> = ({ ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" {...props}>
      
			<path
				d="M10.5 18.3333C15.1023 18.3333 18.8333 14.6024 18.8333 9.99999C18.8333 5.39762 15.1023 1.66666 10.5 1.66666C5.89759 1.66666 2.16663 5.39762 2.16663 9.99999C2.16663 14.6024 5.89759 18.3333 10.5 18.3333Z"
				stroke="#292D32"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.16667 2.5H8C6.375 7.36667 6.375 12.6333 8 17.5H7.16667"
				stroke="#292D32"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 2.5C14.625 7.36667 14.625 12.6333 13 17.5"
				stroke="#292D32"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3 13.3333V12.5C7.86667 14.125 13.1333 14.125 18 12.5V13.3333"
				stroke="#292D32"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3 7.5C7.86667 5.875 13.1333 5.875 18 7.5"
				stroke="#292D32"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
