import { Button, TextInput } from "@mantine/core";
import { RegistrationCard } from "component/common/Layout/registrationCard";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ProfileIcon, EmailIcon } from "assets/svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  otpSchema,
  passResetReqSchema,
  resetPasswordSchema,
} from "../authWrapper/schema";
import { useMutation } from "@tanstack/react-query";
import {
  ICreateEmailType,
  ICreatePasswordType,
  IOtpType,
  IResetPasswordType,
  confirmOTP,
  createAnEmail,
  createPassword,
  resetPassword,
} from "services/authServices/services";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { ConfirmPassword } from "../confirmPassword";
import { AddNewPassword } from "../addPassword";
import { notify } from "shared";

export const ForgotPassword = ({
  openForgetPassword,
  setOpenForgetPassword,
  handleOpenLogin,
}: {
  openForgetPassword: boolean;
  setOpenForgetPassword: Dispatch<SetStateAction<boolean>>;
  handleOpenLogin: () => void;
}) => {
  const [openConfirmPassword, setOpenConfirmPassword] =
    useState<boolean>(false);
  const [secretKey, setSecretKey] = useState("");
  const [openNewPassword, setOpenNewPassword] = useState(false);

  const navigate = useNavigate();
  const { mutate, isPending: isFetching } = useMutation({
    mutationFn: async (payload: IResetPasswordType) =>
      await resetPassword({ ...payload }),
    onSuccess: async (response) => {
      setOpenConfirmPassword(true);
      setOpenForgetPassword(false);
      setSecretKey(response?.data);
      // navigate("/admin");
    },
    onError: async (response) => {
      notifications.show({
        message: response?.message,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { mutate: otpMutate, isPending: isValidating } = useMutation({
    mutationFn: async (payload: IOtpType) => await confirmOTP({ ...payload }),
    onSuccess: async (response) => {
      setOpenConfirmPassword(false);
      setOpenNewPassword(true);
    },
    onError: async (response) => {
      notifications.show({
        message: response?.message,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { mutate: passwordMutate, isPending: isCreating } = useMutation({
    mutationFn: async (payload: ICreatePasswordType) =>
      await createPassword({ ...payload }),
    onSuccess: async (response) => {
      handleOpenLogin();
      setOpenNewPassword(false);
      notify.success({
        message: "Sucessfull",
        subtitle: response?.message || "Password created successfully",
      });
    },
    onError: async (response) => {
      notifications.show({
        message: response?.message,
      });
      // setOpenConfirmPassword(false);
    },
  });

  const { mutate: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      setSecretKey(response?.data);
      setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async (response) => {
      notify.error({
        message: "Bad request",
        subtitle: "error",
      });
    },
  });

  const otpMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(passResetReqSchema),
    // defaultValues,
  });

  const otpMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(otpSchema),
    // defaultValues,
  });

  const { otp } = otpMethod.watch();

  const newPasswordMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(resetPasswordSchema),
    // defaultValues,
  });

  const onSubmit = (payload: IResetPasswordType) => {
    mutate(payload);
  };

  const onSubmitOTP = (payload: IOtpType) => {
    otpMutate({
      secret_key: secretKey,
      activate_user: true,
      otp: payload?.otp,
    });
  };

  const onSubmitNewPassword = (payload: ICreatePasswordType) => {
    passwordMutate({
      password: payload?.password,
      secret_key: secretKey,
      otp,
    });
  };
  return (
    <>
      <RegistrationCard
        show={openForgetPassword}
        onClose={() => setOpenForgetPassword(false)}
      >
        <div className="w-full flex justify-center">
          <span className="text-xl font-bold">Forget Password</span>
        </div>
        <FormProvider {...otpMethods}>
          <form
            className="flex flex-col gap-5 mt-6 w-full"
            onSubmit={otpMethods.handleSubmit(onSubmit)}
          >
            <TextInput
              size="md"
              label="Email"
              placeholder="Enter email address..."
              leftSection={<img src={ProfileIcon} alt="profile icon" />}
              className=" text-[5px]"
              styles={{
                input: {
                  fontSize: "14px",
                },
              }}
              {...otpMethods.register("email")}
            />

            <Button size="md" type="submit" loading={isFetching}>
              Continue
            </Button>
          </form>
        </FormProvider>
      </RegistrationCard>

      <ConfirmPassword
        openConfirmPassword={openConfirmPassword}
        setOpenConfirmPassword={setOpenConfirmPassword}
        otpMethod={otpMethod}
        onSubmit={onSubmitOTP}
        handleSignUp={() => {}}
        resendOTP={mutate}
        isLoading={isLoading || isValidating}
        requestOTPMethod={otpMethods}
        type="forgot-password"
      />

      <AddNewPassword
        openNewPassword={openNewPassword}
        methods={newPasswordMethod}
        onSubmit={onSubmitNewPassword}
        isLoading={isCreating}
      />
    </>
  );
};
