import React from "react";
import { IRegistrationType } from "./types";

export const RegistrationCard = ({
  show,
  onClose,
  children,
}: IRegistrationType) => {
  if (!show) {
    return null;
  }
  return (
    <div
      className="fixed inset-0 no-scrollbar z-[-9999px] flex items-center justify-center w-full bg-[#00000070] bg-opacity-50 backdrop-blur-sm overflow-hidden"
      onClick={onClose}
    >
      <div
        className="bg-white no-scrollbar p-6 rounded-xl shadow-lg max-w-[390px] w-full h-fit max-h-[95vh] hideScrollBar overflow-auto "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full flex flex-col gap-3">
          <small className="mx-auto text-red text-sm font-bold text-[#007BD4]">
            Welcome Back!
          </small>
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
};
