import { SearchPage, Homepage } from "pages";
import { routes } from "routes";
import { routerType } from "types";

export const pagesData: routerType[] = [
  {
    path: routes.HOME.path,
    element: <Homepage />,
    title: routes.HOME.name,
    useAuth: false,
  },
  {
    path: routes.SEARCH.path,
    element: <SearchPage />,
    title: routes.SEARCH.name,
    useAuth: false,
  },
  // {
  //   path: routes.SIGNUP.path,
  //   element: <SignUp />,
  //   title: routes.SIGNUP.name,
  // },
  // {
  //   path: routes.LOGIN.path,
  //   element: <LoginCard />,
  //   title: routes.LOGIN.name,
  // },
  // {
  //   path: routes.FORGOTPASSWORD.path,
  //   element: <ForgotPassword />,
  //   title: routes.FORGOTPASSWORD.name,
  // },
  //add other paths here
];
