import React from "react";
import {
  BlogContainer,
  BlogDate,
  BlogHeader,
  BlogImg,
  BlogInfo,
  BlogItem,
  BlogReadMore,
  BlogTop,
  Blogs,
  DashedTitle,
} from "./BlogStyles";
// import { Paragraph } from "../../../../assets";
 import { MdArrowOutward } from "react-icons/md";
// import img from "../../../../assets/images/blogimg.png";
import { BsDashLg } from "react-icons/bs";
import { LuDot } from "react-icons/lu";
import { Paragraph, blogimg } from "assets";

export const Blog = () => {
  return (
    <BlogContainer>
      <DashedTitle>
        <BsDashLg /> Our Blogs
      </DashedTitle>
      <BlogTop>
        <BlogHeader>Check out our blogs</BlogHeader>
        <Paragraph>
          We're connecting people to resources, places and communities that can
          help them thrive and live a good life.
        </Paragraph>
      </BlogTop>

      <Blogs>
        <BlogItem>
          <BlogImg src={blogimg} />
          <BlogDate>
            Nov 12, 2023 <LuDot /> 11:45 PM{" "}
          </BlogDate>
          <BlogInfo>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
            mollitia sapiente ratione totam sed eum vero reprehenderit
            aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
            vel eum iure nulla numquam.
          </BlogInfo>
          <BlogReadMore>
            Read More <MdArrowOutward />
          </BlogReadMore>
        </BlogItem>

        <BlogItem>
          <BlogImg src={blogimg} />
          <BlogDate>
            Nov 12, 2023 <LuDot /> 11:45 PM{" "}
          </BlogDate>
          <BlogInfo>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
            mollitia sapiente ratione totam sed eum vero reprehenderit
            aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
            vel eum iure nulla numquam.
          </BlogInfo>
          <BlogReadMore>
            Read More <MdArrowOutward />
          </BlogReadMore>
        </BlogItem>

        <BlogItem>
          <BlogImg src={blogimg} />
          <BlogDate>
            Nov 12, 2023 <LuDot /> 11:45 PM{" "}
          </BlogDate>
          <BlogInfo>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
            mollitia sapiente ratione totam sed eum vero reprehenderit
            aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
            vel eum iure nulla numquam.
          </BlogInfo>
          <BlogReadMore>
            Read More <MdArrowOutward />
          </BlogReadMore>
        </BlogItem>

        <BlogItem>
          <BlogImg src={blogimg} />
          <BlogDate>
            Nov 12, 2023 <LuDot /> 11:45 PM{" "}
          </BlogDate>
          <BlogInfo>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
            mollitia sapiente ratione totam sed eum vero reprehenderit
            aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
            vel eum iure nulla numquam.
          </BlogInfo>
          <BlogReadMore>
            Read More <MdArrowOutward />
          </BlogReadMore>
        </BlogItem>
      </Blogs>
    </BlogContainer>
  );
};


