import { themeColors } from "Themes/ThemeColors";
import styled from "styled-components";

export const FooterContainer = styled.div``;

export const FooterTop = styled.div`
  display: flex;
  background-color: ${themeColors.footerBlue};
  color: white;
  font-size: 0.8rem;
  // gap: 5rem;
  padding: 3rem 7rem;
  justify-content: space-between;
`;

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    cursor: pointer;

    &:hover {
      color: ${themeColors.navbarBtnBlue};
      transform: scale(1.1);
    }
  }
`;

export const FooterMenuHeader = styled.p`
  color: ${themeColors.footerHeaderGrey};

  &:hover {
    color: ${themeColors.footerHeaderGrey} !important;
    transform: scale(1) !important;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  font-size: 1.5rem;
  gap: 1.5rem;

  div {
    background-color: ${themeColors.footerIconGrey};
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      color: ${themeColors.navbarBtnBlue};
    }
  }
`;

export const CopyrightContainer = styled.div`
  background-color: ${themeColors.white};
  color: black;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 7rem;
  font-weight: 700;

  p {
    font-size: 0.8rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  span {
    color: ${themeColors.grey};
  }
`;
