import React from "react";
import { Navbar } from "./NavBar";

export const Header = ({
  handleOpenSignin,
}: {
  handleOpenSignin: () => void;
}) => {
  return <Navbar handleOpenSignin={handleOpenSignin} />;
};
