import React, { FC } from "react";
import {
  CopyrightContainer,
  FooterContainer,
  FooterMenu,
  FooterMenuHeader,
  FooterSocials,
  FooterTop,
} from "./FooterStyles";
import { Div, Paragraph, Span } from "../../../../assets"
import { LuDot } from "react-icons/lu";
import {
  RiTwitterXFill,
  RiFacebookFill,
  RiInstagramLine,
  RiLinkedinBoxLine,
} from "react-icons/ri";

interface FooterProps {
  prop1: `string`;
}
export const Footer: FC<FooterProps> = () => {
  return (
    <FooterContainer>
      <FooterTop>
        <FooterMenu>
          <FooterMenuHeader>Connect</FooterMenuHeader>

          <Paragraph>nameshare@gmail.com</Paragraph>
          <Paragraph>+44 124 568 7890</Paragraph>
        </FooterMenu>

        <FooterMenu>
          <FooterMenuHeader>Quick links</FooterMenuHeader>

          <Paragraph>Home</Paragraph>
          <Paragraph>Post a job</Paragraph>
          <Paragraph>My jobs</Paragraph>
        </FooterMenu>

        <FooterMenu>
          <FooterMenuHeader>Digital Services</FooterMenuHeader>

          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
        </FooterMenu>

        <FooterMenu>
          <FooterMenuHeader>Home Services</FooterMenuHeader>

          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
          <Paragraph>Name here</Paragraph>
        </FooterMenu>

        <FooterMenu>
          <FooterMenuHeader>Footer</FooterMenuHeader>

          <FooterSocials>
            <Div>
              <RiLinkedinBoxLine />
            </Div>
            <Div>
              <RiTwitterXFill />
            </Div>
            <Div>
              <RiInstagramLine />
            </Div>
            <Div>
              <RiFacebookFill />
            </Div>
          </FooterSocials>
        </FooterMenu>
      </FooterTop>

      <CopyrightContainer>
        <Paragraph>Copyrights 2024. All rights reserved</Paragraph>

        <Div>
          <Paragraph>Privacy policy</Paragraph>
          <Span>
            <LuDot />
          </Span>
          <Paragraph>Terms and conditions</Paragraph>
          <Span>
            <LuDot />
          </Span>
          <Paragraph>Site map</Paragraph>
          <Span>
            <LuDot />
          </Span>
          <Paragraph>Legal</Paragraph>
        </Div>
      </CopyrightContainer>
    </FooterContainer>
  );
};
