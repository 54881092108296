import Api from "utils/auth/api";

export interface ISignUpType {
  email: string;
  fullname: string;
  post_code: string;
  phone: string;
  password: string;
  country_code: string;
  otp: string;
  secret_key: string;
}

export interface ICreateEmailType {
  email: string;
}

export interface IGoogleLogin {
  code: string;
}

export interface IUserProfile {
  data: {
    fullname: string;
    phone: string;
    gender: string;
    profile_picture: string;
    dob: string;
    about_me: string;
  };
}

export interface IOtpType {
  otp: string;
  secret_key: string;
  activate_user: boolean;
}

export interface ICreatePasswordType {
  otp: string;
  secret_key: string;
  password: boolean;
}

export interface ILoginUserType {
  email: string;
  password: string;
}

export interface IResetPasswordType {
  email: string;
}

export const createAnAccount = async (payload: ISignUpType) => {
  return await Api.post("/user/", payload).then((res) => res.data);
};

export const createAnEmail = async (payload: ICreateEmailType) => {
  return await Api.post("/user/send-creation-email/", payload).then(
    (res) => res.data
  );
};

export const googleLogin = async (payload: IGoogleLogin) => {
  return await Api.post("/google", payload).then((res) => res.data);
};

export const getUserProfile = async () => {
  return await Api.get<IUserProfile>("/user/profile/").then((res) => res.data);
};

export const confirmOTP = async (payload: IOtpType) => {
  return await Api.post("/user/validate-otp/", payload).then((res) => res.data);
};

export const loginUser = async (payload: ILoginUserType) => {
  return await Api.post("/user/login/", payload).then((res) => res.data);
};

export const resetPassword = async (payload: IResetPasswordType) => {
  return await Api.post("/user/reset-password/", payload).then(
    (res) => res.data
  );
};

export const createPassword = async (payload: ICreatePasswordType) => {
  return await Api.post("/user/create-password/", payload).then(
    (res) => res.data
  );
};
