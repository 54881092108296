import { RegistrationCard } from "./registrationCard";
import { AuthContainer } from "pages/auth/authWrapper/authWrapper";
import React, { FC, ReactNode, useContext, useState } from "react";
import { Header } from "./Header";

import { Explore, FAQ, Blog, OtherServices, LogosCarousel } from "component";

import { Footer } from "./Footer";
// import Explore from "./Explore/Explore";
// import Blog from "./Blog/Blog";
// import FAQ from "./FAQ/FAQ";

import { LayoutContainer } from "./LayoutStyles";
import { LayoutContext } from "context/LayoutContext";

interface LayoutProps {
  children: ReactNode;
}
export const Layout: FC<LayoutProps> = ({ children }) => {
  const [openLogin, setOpenLogin] = useState(false);

  const { modalOpen } = useContext(LayoutContext);
  return (
    <>
      <LayoutContainer modalOpen={modalOpen}>
        <Header
          handleOpenSignin={() => {
            setOpenLogin(true);
          }}
        />
        <main>{children}</main>
        {/* <LogosCarousel /> */}
        <Explore />
        <FAQ />
        <Blog />
        <OtherServices />
        <Footer prop1={"string"} />
      </LayoutContainer>
      <AuthContainer openLogin={openLogin} setOpenLogin={setOpenLogin} />
    </>
  );
};
