import { themeColors } from "Themes/ThemeColors";
import styled from "styled-components";
import img from "../../../../assets/images/HeroWorkerBg.png";

const scrollStyle = `
position: absolute;
top: 50%;
background-color: ${themeColors.white};
width: fit-content;
color: black;
font-size: 1.1rem;
padding: .3rem;
border-radius: 50%;
cursor: pointer;
`;

export const HeroContainer = styled.div`
  display: flex;
`;

export const HeroLeft = styled.div`
  flex: 6;
  background-color: ${themeColors.heroSectionBlue};
  padding: 3rem 3rem 3rem 4rem;
  color: ${themeColors.white};
`;

export const HeroRight = styled.div`
  flex: 4;
  background-color: ${themeColors.heroSectionIndigo};
  padding-top: 8rem;
  position: relative;
  color: ${themeColors.white};
`;

export const HeroQuoteBtnContainer = styled.div`
  border-radius: 10px;
  background: linear-gradient(
    to right,
    ${themeColors.gradientBlue},
    ${themeColors.gradientYellow}
  );
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const GetQuoteButton = styled.button`
  background-color: ${themeColors.heroSectionBlue};
  margin: 1px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 1rem;

  span {
  }
`;

export const HeroCallText = styled.p`
  margin-top: 3rem;
  font-size: 4rem;
  line-height: 4.5rem;
  font-weight: 600;
`;

export const HeroCallStyledText = styled.span`
  display: inline-block;

  img {
    position: absolute;
  }
`;

export const HeroServiceSearch = styled.div`
  margin-top: 3rem;
  background-color: ${themeColors.heroSearchBlue};
  display: flex;
  border-radius: 20px;

  div {
    padding: 1rem;
  }
`;

export const HeroServiceSearchLocation = styled.div`
  flex: 3;
  border: 1px solid ${themeColors.heroSearchBorderGrey};
  color: ${themeColors.heroLocationGrey};
  border-radius: 20px 0 0 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const HeroServiceSearchInput = styled.div`
  flex: 7;
  border: 1px solid ${themeColors.heroSearchBorderGrey};
  color: ${themeColors.heroSearchInputGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  border-radius: 0 20px 20px 0;

  input {
    width: 80%;
    padding: 0 2rem;
    height: 100%;
  }
`;

export const HeroServiceSearchBtn = styled.button`
  background-color: ${themeColors.navbarBtnBlue};
  color: ${themeColors.white};
  height: 100%;
  padding: 1rem;
  border: 1px solid ${themeColors.heroSearchBtnBorderBlue};
  border-radius: 0 20px 20px 0;
`;

export const HeroPopularSearch = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 0.9rem;

  p {
    font-weight: 600;
  }

  div {
    display: flex;
    gap: 0.5rem;
  }
`;

export const HeroPopularSearchItem = styled.div`
  border: 1px solid ${themeColors.popularItemBorderGrey};
  border-radius: 20px;
  padding: 0.3rem 1rem;
  cursor: pointer;
`;

export const HeroTrustPilot = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  img {
    width: 1.2rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  p {
    font-size: 0.6rem;
  }
`;

export const HeroWorkersBox = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  padding: 0 2rem;
  justify-content: space-between;
  // width: 96px;
  // height: 92px;

  img {
    width: 4rem;
    // object-fit: contain;
  }
`;

export const HeroMainWorker = styled.div`
  position: relative;
  width: 450px;
  height: 420px;
  margin: auto;
`;

export const ScrollLeft = styled.div`
  ${scrollStyle};
  left: 0;
  margin-left: 1rem;
`;

export const ScrollRight = styled.div`
  ${scrollStyle}
  right: 0;
  margin-right: 1rem;
`;

export const HeroMan = styled.img`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const HeroWorkerBg = styled.img`
  width: 450px;
  height: 420px;
  position: absolute;
`;

export const HeroWorkerTop = styled.div`
  width: 450px;
  height: 35px;
  border-radius: 10px 10px 0 0;
  background-color: black;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  font-weight: 700;
`;

export const HeroWorkerInfoContainer = styled.div`
  width: 400px;
  border-radius: 10px;
  background-color: black;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto auto 1rem;
  z-index: 3;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeroWorkerInfo = styled.div`
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  font-weight: 700;
`;

export const HeroWorkerRating = styled.div`
  display: flex;
  align-items: center;
  color: #4ade80;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
`;

export const HeroWorkerBtn = styled.button`
  background-color: ${themeColors.navbarBtnBlue};
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 700;
`;
