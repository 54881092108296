import React, { useState, createContext } from "react";

interface LayoutContextProviderProps {
  children: React.ReactNode;
}

interface LayoutContextType {
  modalOpen: boolean;
  activeModal: string
  openModal: () => void
  closeModal: () => void
  setOpenModal: (modalName: string) => void;
}

const LayoutContext = createContext<LayoutContextType>({
  modalOpen: false,
  activeModal: "",
  openModal: () => {},
  closeModal: () => {},
  setOpenModal: () => {}
});

const LayoutProvider: React.FC<LayoutContextProviderProps> = ({ children }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const[activeModal, setActiveModal] = useState<string>("")

    const openModal = (): void => {
        setModalOpen(true)
    }

    const closeModal = (): void => {
        setModalOpen(false)
    }

    const setOpenModal = (modalName: string): void => {
      setActiveModal(modalName)
    }

    const contextValue: LayoutContextType = {
        modalOpen,
        openModal,
        closeModal,
        activeModal,
        setOpenModal
    }

    return (
        <LayoutContext.Provider value={contextValue}>
            {children}
        </LayoutContext.Provider>
    )
};

export {LayoutContext, LayoutProvider}