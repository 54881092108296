import img from "../assets/images/service.png";

export const ExploreServices = [
  {
    serviceHeader: "Home Services",
    services: [
      {
        name: "Home Maintenance & Repair",
        img: img,
        list: [
          "Plumbing",
          "Electrical Work",
          "Capentry",
          "Painting",
          "Handyman Services",
        ],
      },
    ],
  },
];
