import { themeColors } from "Themes/ThemeColors";
import styled from "styled-components";

export const BlogContainer = styled.div`
  margin: 3rem 3rem 0;
`;

export const DashedTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 900;
  gap: 0.3rem;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
`;

export const BlogTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  p {
    width: 30%;
  }
`;

export const BlogHeader = styled.div`
  font-size: 3rem;
  font-weight: 700;
`;

export const BlogItem = styled.div`
  flex: 1;
`;

export const BlogImg = styled.img``;

export const BlogDate = styled.p`
  color: ${themeColors.footerHeaderGrey};
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0.8rem 0;
  padding-left: 0.2rem;
`;

export const BlogInfo = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  font-weight: 700;
`;

export const BlogReadMore = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${themeColors.navbarBtnBlue};
  margin: 0.5rem 0;
  font-size: 0.9rem;
  font-weight: 700;
  padding-bottom: 0.1rem;
  border-bottom: 2px solid ${themeColors.navbarBtnBlue};
  width: fit-content;
`;

export const Blogs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;
