import { Box, Button, Checkbox, PasswordInput, TextInput } from "@mantine/core";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  PadLockIcon,
  EmailIcon,
  FacebookIcon,
  AppleIcon,
  GoogleIcon,
} from "assets/svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RegistrationCard } from "component/common/Layout/registrationCard";
import { routes } from "routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ILoginUserType, loginUser } from "services/authServices/services";
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { signinSchema, signupSchema } from "../authWrapper/schema";
import { notify } from "shared/notifications/notify";
import { HttpStatus } from "utils/error";
import { isAuthenticated, setRefreshToken, setToken } from "utils/auth/auth";
// import { isPending } from "@reduxjs/toolkit";

export const LoginForm = ({
  handleOpenForgetPassword,
  handleOpenSignUp,
  setOpenLogin,

  openLogin,
}: {
  handleOpenSignUp: Dispatch<SetStateAction<boolean>>;
  handleOpenForgetPassword: () => void;
  openLogin: boolean;
  setOpenLogin: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const errorParams = searchParams.get("error");
  const accessToken = searchParams.get("access_token");

  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: ILoginUserType) =>
      await loginUser({ ...payload }),
    onSuccess: async ({ data }) => {
      setToken(data?.access);
      setRefreshToken(data?.refresh);
      setOpenLogin(false);
      queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });

      isAuthenticated();
      return notify.success({
        message: "Login successful",
        subtitle: `Welcome back champ`,
      });
    },
    onError: async ({ response }: any) => {
      return notify.error({
        message: "Bad request",
        subtitle: response?.data?.error,
      });
    },
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(signinSchema),
  });
  const { errors } = methods.formState;

  const onSubmit = (payload: ILoginUserType) => {
    mutate(payload);
  };

  // Token data is invalid
  useEffect(() => {
    if (errorParams) {
      navigate(routes.HOME.path);
      notify.error({
        message: "Error",
        subtitle: decodeURIComponent(errorParams),
      });
    } else if (accessToken) {
      setToken(accessToken);
      if (isAuthenticated()) {
        navigate(routes.HOME.path);
      }
    }
  }, [errorParams, accessToken]);

  return (
    <RegistrationCard show={openLogin} onClose={() => setOpenLogin(false)}>
      <div className="w-full flex justify-center">
        <span className="text-xl font-bold">Login with your email</span>
      </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-5 mt-6 w-full"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TextInput
            size="md"
            label="Email"
            placeholder="Email address..."
            leftSection={<img src={EmailIcon} alt="email icon" />}
            className=" text-xs"
            styles={{
              input: {
                fontSize: "14px",
              },
            }}
            {...methods.register("email")}
            error={errors?.email?.message}
          />

          <PasswordInput
            placeholder="Password"
            label="Password"
            className="w-full"
            styles={{
              input: {
                fontSize: "14px",
              },
            }}
            size="md"
            leftSection={<img src={PadLockIcon} />}
            {...methods.register("password")}
            error={errors?.password?.message}
          />

          <div className="flex w-full justify-between">
            {" "}
            <Checkbox defaultChecked label="Stay login" size="xs" />
            <div
              onClick={handleOpenForgetPassword}
              className="text-xs underline cursor-pointer"
            >
              Forgot Password?
            </div>
          </div>
          <Button size="md" type="submit" loading={isPending}>
            Login
          </Button>
        </form>
      </FormProvider>
      <div>
        <div className="w-full flex flex-col items-center my-3">
          <span className="text-sm text-[#7A7B7F]">Or Login with</span>
          <div className="w-full my-4  flex flex-col gap-3">
            <Button
              fullWidth
              size="md"
              style={{
                backgroundColor: "#F6F6F6",
                color: "#212329",
                fontWeight: "normal",
              }}
              onClick={() => {
                const url = ` https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&access_type=online&response_type=token`;
                window.open(url, "_self");
              }}
            >
              <img src={GoogleIcon} className="mx-3" alt="google icon" />{" "}
              Continue with Google
            </Button>
            <Button
              fullWidth
              size="md"
              style={{
                backgroundColor: "#F6F6F6",
                color: "#212329",
                fontWeight: "normal",
              }}
            >
              <img src={AppleIcon} className="mx-3" alt="apple icon" /> Continue
              with Apple ID
            </Button>
            <Button
              fullWidth
              size="md"
              style={{
                backgroundColor: "#F6F6F6",
                color: "#212329",
                fontWeight: "normal",
              }}
            >
              <img src={FacebookIcon} className="mx-3" /> Continue with Facebook
            </Button>
          </div>
        </div>
        <div className="w-full flex justify-center mt-3">
          <span className=" text-sm cursor-pointer">
            Don't you have an account?{" "}
            <span
              onClick={() => {
                setOpenLogin(false);
                handleOpenSignUp(true);
              }}
              className=" text-[#4188F9] underline"
            >
              Register
            </span>
          </span>
        </div>
      </div>
    </RegistrationCard>
  );
};
