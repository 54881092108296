/* eslint-disable camelcase */
import { jwtDecode } from "jwt-decode";
import { IDecodedUser } from "./auth.type";
// Separate token key for different enviroment to prevent one env auth state affecting the other environment open on the sam browser window
const host = window.location.hostname.replaceAll(".", "");
const ACCESS_TOKEN = `${host}-accessToken`;
const REFRESH_TOKEN = `${host}-refreshToken`;

export const setToken = async (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const getDecodedJwt = (tokn: string = ""): IDecodedUser => {
  try {
    const token = getToken();
    const t = token || tokn;
    const decoded = jwtDecode<IDecodedUser>(t);
    return decoded;
  } catch (e) {
    return {} as IDecodedUser;
  }
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const removeToken = async () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const logOut = async (cb: VoidFunction) => {
  await removeToken();
  cb();
};

export const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt();
    if (decodedToken) {
      const { exp } = decodedToken;
      const currentTime = Date.now() / 1000;
      return exp > currentTime;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getDecodedUser = (tokn: string = ""): IDecodedUser => {
  try {
    const token = getToken();
    const t = token || tokn;
    const decoded = jwtDecode<IDecodedUser>(t);
    return decoded;
  } catch (e) {
    return {} as IDecodedUser;
  }
};
