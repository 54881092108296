import { Avatar, Box, Text } from "@mantine/core";
import {
  NavbarBottom,
  NavbarBrowseButton,
  NavbarContainer,
  NavbarHr,
  NavbarLogo,
  NavbarSignInButton,
  NavbarTop,
  NavbarTopLeftMenu,
  NavbarTopLeftMenuItem,
  NavbarTopMenu,
  NavbarTopRightMenu,
  NavbarTopRightMenuItem,
  ProviderText,
} from "./NavbarStyles";
import {
  ComputerIcon,
  Hamburger,
  PaintBrushIcon,
  logo,
  Paragraph,
  GlobalIcon,
} from "assets";

import { NavMenuList } from "data";
import { SelectIcon } from "assets/svg";
import { useContext, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import { getDecodedUser, isAuthenticated, removeToken } from "utils/auth/auth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";

export const Navbar = ({
  handleOpenSignin,
}: {
  handleOpenSignin: () => void;
}) => {
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();

  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState<boolean>(false);

  const { openModal, setOpenModal }: any = useContext(LayoutContext);

  const nameParts = userDetails?.data?.fullname?.split(" ");
  const firstName = nameParts?.[0];

  const handleModalClick = (modalName: string): void => {
    openModal();
    setOpenModal(modalName);
  };

  const handleLogOut = () => {
    queryClient.clear();
    removeToken();
    setModalState(false);
  };

  return (
    <NavbarContainer>
      <NavbarTop>
        <NavbarLogo src={logo} />
        <NavbarTopMenu>
          <NavbarTopLeftMenu>
            <NavbarTopLeftMenuItem>
              <ComputerIcon width={40} height={40} />
              <Paragraph>Digital Services</Paragraph>
            </NavbarTopLeftMenuItem>
            <NavbarTopLeftMenuItem>
              <PaintBrushIcon width={40} height={40} />
              <Paragraph>Home Services</Paragraph>
            </NavbarTopLeftMenuItem>
          </NavbarTopLeftMenu>
          <NavbarTopRightMenu>
            <NavbarTopRightMenuItem>
              <Paragraph>Home</Paragraph>
            </NavbarTopRightMenuItem>
            <NavbarTopRightMenuItem>
              <ProviderText>Become a Provider</ProviderText>
            </NavbarTopRightMenuItem>
            <NavbarTopRightMenuItem>
              <GlobalIcon height={20} width={20} fill="white" />
              <Paragraph>English</Paragraph>
            </NavbarTopRightMenuItem>
            {!userDetails?.data?.fullname && (
              <NavbarSignInButton onClick={handleOpenSignin}>
                Sign In
              </NavbarSignInButton>
            )}
            {userDetails?.data?.fullname && (
              <div>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    border: "1px solid #94A3B8",
                    padding: "3px",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalState(!modalState)}
                >
                  <Avatar
                    radius="xl"
                    src={userDetails?.data?.profile_picture || ""}
                  />
                  <Text style={{ fontWeight: 600 }}>{firstName || ""}</Text>{" "}
                  <img
                    src={SelectIcon}
                    alt="select icon"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                  />
                </Box>
                <div
                  onClick={() => {
                    removeToken();
                    queryClient.clear();
                  }}
                  id="dropdown"
                  className={`z-10 ${
                    modalState ? "block" : "hidden"
                  }  bg-white divide-y divide-gray-100 right-16 absolute rounded-lg shadow-lg w-44 dark:bg-gray-700`}
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li
                      onClick={() => {
                        handleLogOut();
                      }}
                    >
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* <NavbarSignInButton
              onClick={() => {
                handleModalClick("login");
              }}
            >
              Sign In
            </NavbarSignInButton> */}
          </NavbarTopRightMenu>
        </NavbarTopMenu>
      </NavbarTop>
      <NavbarHr />
      <NavbarBottom>
        <NavbarBrowseButton>
          <Hamburger height={20} width={20} />
          <Paragraph>Browse Categories</Paragraph>
        </NavbarBrowseButton>
        {/* TODO: Add click option here to link to something 
       so this might be modified to accomodate routing
        */}
        {NavMenuList.map((item, index) => (
          <Paragraph key={`${index}-${item}`}>{item}</Paragraph>
        ))}
      </NavbarBottom>
    </NavbarContainer>
  );
};
