import { themeColors } from "Themes/ThemeColors";
import styled from "styled-components";

export const OtherServicesContainer = styled.div`
    margin: 3rem;

    p {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
    }

`;

export const OtherServicesFlexBox = styled.div`
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    div {
        cursor: pointer;
        background-color: #e6e9ed;
        padding: .5rem;
        font-size: .9rem;
        border: 1px solid ${themeColors.grey};
        border-radius: 20px;
    }
`;
