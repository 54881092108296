import { FC } from "react";
import {
	Blog,
	Explore,
	FAQ,
	HeroSection,
	LogosCarousel,
	OtherServices,
} from "component";

interface HomepageProps {
	//prop1: string
}
export const Homepage: FC<HomepageProps> = () => {
	return (
		<>
			<HeroSection />
			<LogosCarousel />
			<Explore />
			<FAQ />
			<Blog />
			<OtherServices />
		</>
	);
};
