import { themeColors } from "Themes/ThemeColors";
import styled, { keyframes } from "styled-components";

const open = keyframes`
    from {
        max-height: 0px;
    }

    to {
       max-height: 400px;
    }
`;

const close = keyframes`
    from {
        max-height: auto;
    }

    to {
        transform: translateX(-100%);
    }
`;


export const FaqContainer = styled.div`
  margin: 3rem;
`;

export const FaqFlexbox = styled.div`
  display: flex;
  gap: 10rem;

  div {
    flex: 1;
  }
`;

export const FaqsBox = styled.div``;

export const Faq = styled.div`
    margin-top: 2rem;

  input {
    display: none;

    &:checked + label + div {
      max-height: 5rem;
      padding: 5px 0px 0;
    }

    &:checked + label {
      color: ${themeColors.navbarBtnBlue};
    }

    &:checked + label::after {
      content: "-";
    }
  }

  label {
    cursor: pointer;
    align-items: center;
    font-weight: 700;
    font-size: 1.2rem;
    display: flex;

    &::after {
      content: "+";
      margin-left: auto;
      font-size: 2rem;
    }
  }

  div {
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
  }
`;

export const FaqHr = styled.hr``;
