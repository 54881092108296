// import NotifErrorIcon from "@/assets/svgs/close-circle.svg";
// import NotifSuccessIcon from "@/assets/svgs/tick-circle.svg";
import { IconCheck, IconX } from "@tabler/icons-react";
import {
  Id,
  toast,
  ToastContent,
  ToastOptions,
  UpdateOptions,
} from "react-toastify";
import { Box, CheckIcon, rem } from "@mantine/core";
import { NotificationMsg } from "./NotificationMsg";

// Notifications props
interface NotifProps extends ToastOptions<object> {
  content?: ToastContent<unknown>;
  message?: string;
  subtitle?: string;
}

// Update notifications props
interface UpdateNotifProps extends UpdateOptions<object> {
  content?: ToastContent<unknown>;
  message?: string;
  subtitle?: string;
}

// Success notifications trigger
const success = ({ content, message, subtitle, ...options }: NotifProps) =>
  toast.success(
    content || <NotificationMsg message={message} subtitle={subtitle} />,
    {
      icon: (
        <Box
          style={{
            padding: "3px",
            borderRadius: "50px",
            backgroundColor: "green",
          }}
        >
          <IconCheck
            color="white"
            style={{ width: rem(15), height: rem(15) }}
          />
        </Box>
      ),
      style: { backgroundColor: "#F5F5F5" },
      ...options,
    }
  );

// Error notifications trigger
const error = ({ content, message, subtitle, ...options }: NotifProps) =>
  toast.error(
    content || (
      <NotificationMsg message={message} subtitle={subtitle} type="error" />
    ),
    {
      icon: (
        <Box
          style={{
            padding: "3px",
            borderRadius: "50px",
            backgroundColor: "red",
          }}
        >
          <IconX color="white" style={{ width: rem(15), height: rem(15) }} />
        </Box>
      ),
      style: { backgroundColor: "#ffefef" },
      ...options,
    }
  );

// Update notifications trigger
const update = (
  id: Id,
  { content, message, subtitle, type, ...props }: UpdateNotifProps
) =>
  toast.update(id, {
    render: content || (
      <NotificationMsg message={message} subtitle={subtitle} />
    ),
    icon: type === "error" ? <div>sksk</div> : <CheckIcon />,
    ...props,
  });

// Dismiss all notifications
const dismissAll = () => toast.dismiss();

export const notify = { success, error, update, dismissAll };
