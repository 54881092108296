import React from "react";
import {
  ExploreContainer,
  ExploreHeader,
  ExploreImg,
  ExploreServicesContainer,
  Service,
  SubServiceHeader,
  SubServiceItems,
} from "./ExploreStyles";
import { ExploreServices } from "data/ExploreData";
import { Paragraph } from "assets";
import { FaChevronRight } from "react-icons/fa";

const data = ExploreServices;

export const Explore = () => {
  return (
    <>
      <ExploreContainer>
        <ExploreHeader>
          Explore {data[0].serviceHeader}
          <Paragraph>
            View more <FaChevronRight />
          </Paragraph>
        </ExploreHeader>
        <ExploreServicesContainer>
          {Array.from({ length: 8 }, (_, index) =>
            data[0].services.map((service, i) => (
              <Service>
                <ExploreImg src={service.img} />
                <SubServiceHeader>{service.name}</SubServiceHeader>
                <SubServiceItems>
                  {service.list.map((item, i) => (
                    <Paragraph>{item}</Paragraph>
                  ))}
                </SubServiceItems>
              </Service>
            ))
          )}
        </ExploreServicesContainer>
      </ExploreContainer>

      <ExploreContainer>
        <ExploreHeader>
          Explore {data[0].serviceHeader}
          <Paragraph>
            View more <FaChevronRight />
          </Paragraph>
        </ExploreHeader>
        <ExploreServicesContainer>
          {Array.from({ length: 8 }, (_, index) =>
            data[0].services.map((service, i) => (
              <Service>
                <ExploreImg src={service.img} />
                <SubServiceHeader>{service.name}</SubServiceHeader>
                <SubServiceItems>
                  {service.list.map((item, i) => (
                    <Paragraph>{item}</Paragraph>
                  ))}
                </SubServiceItems>
              </Service>
            ))
          )}
        </ExploreServicesContainer>
      </ExploreContainer>
    </>
  );
};


