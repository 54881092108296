// import { FormTextInput } from "@/component/common/FormTextInput";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileIcon, EmailIcon, PostCodeIcon, PadLockIcon } from "assets/svg";

import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Checkbox, PasswordInput, TextInput } from "@mantine/core";
import { RegistrationCard } from "component/common/Layout/registrationCard";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ICreateEmailType,
  IOtpType,
  IResetPasswordType,
  ISignUpType,
  confirmOTP,
  createAnAccount,
  createAnEmail,
} from "services/authServices/services";
import {
  otpSchema,
  passResetReqSchema,
  signupSchema,
} from "../authWrapper/schema";
import { notifications } from "@mantine/notifications";
import { ConfirmPassword } from "../confirmPassword";
import { notify } from "shared/notifications/notify";
import { isAuthenticated, setRefreshToken, setToken } from "utils/auth/auth";

export interface IFormType {
  email: string;
  fullname: string;
  postCode: string;
  phone: string;
  password: string;
  countryCode: string;
  terms: boolean;
  otp?: string;
}

const defaultValues: IFormType = {
  email: "",
  fullname: "",
  phone: "",
  countryCode: "234",
  password: "",
  postCode: "",
  terms: false,
  otp: "",
};

export const SignUp = ({
  openSignupForm,
  setOpenSignUp,
  handleOpenLoginForm,
  handleOpenLogin,
}: any) => {
  const queryClient = useQueryClient();
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const methods = useForm<IFormType>({
    mode: "onChange",
    resolver: yupResolver(signupSchema),
    defaultValues,
  });

  const otpRequestMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(passResetReqSchema),
  });

  const { phone, terms } = methods.watch();
  const { errors } = methods.formState;

  const { mutate, isPending: isFetching } = useMutation({
    mutationFn: async (payload: ISignUpType) =>
      await createAnAccount({ ...payload }),
    onSuccess: async ({ data }) => {
      window.location.reload();
      setOpenConfirmPassword(false);
      setOpenSignUp(false);
      setToken(data?.access);
      setRefreshToken(data?.refresh);
      queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });
      isAuthenticated();
      notify.success({
        subtitle: "Successfully created",
        message: "Welcome back champ",
      });
    },
    onError: async (response) => {
      notify.error({
        message: response?.message,
      });
    },
  });

  const { mutate: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      setOpenSignUp(false);
      setSecretKey(response?.data);
      setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  // const { mutate: otpMutate } = useMutation({
  //   mutationFn: async (payload: IOtpType) => await confirmOTP({ ...payload }),
  //   onSuccess: async (response) => {
  //     setOpenConfirmPassword(false);
  //     handleSignUp(false);
  //     handleOpenLogin(true);
  //   },
  //   onError: async (response) => {
  //     notifications.show({
  //       message: response?.message,
  //     });
  //     // setOpenConfirmPassword(false);
  //   },
  // });

  const onSubmitEmail = (payload: ICreateEmailType) => {
    emailMutate({
      email: payload?.email,
    });
  };

  const onSubmit = (payload: IFormType) => {
    mutate({
      country_code: payload?.countryCode,
      email: payload?.email,
      fullname: payload?.fullname,
      password: payload?.password,
      phone: payload?.phone,
      post_code: payload?.postCode,
      otp: payload?.otp!,
      secret_key: secretKey,
    });
  };

  // const onSubmitOTP = (payload: IOtpType) => {
  //   otpMutate({
  //     secret_key: secretKey,
  //     activate_user: true,
  //     otp: payload?.otp,
  //   });
  // };

  return (
    <>
      <RegistrationCard
        show={openSignupForm}
        onClose={() => setOpenSignUp(false)}
      >
        <div className="w-full flex justify-center">
          <span className="text-xl font-bold">Sign up to Audtiklance</span>
        </div>
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-5 mt-6 w-full"
            onSubmit={methods.handleSubmit(onSubmitEmail)}
          >
            <TextInput
              size="md"
              placeholder="Fullname"
              leftSection={<img src={ProfileIcon} alt="profile icon" />}
              className=" text-xs"
              styles={{
                input: {
                  fontSize: "14px",
                },
              }}
              // name="fullname"
              {...methods.register("fullname")}
              error={errors?.fullname?.message}
            />
            <TextInput
              size="md"
              placeholder="Email address"
              leftSection={<img src={EmailIcon} />}
              // name="email"
              {...methods.register("email")}
              styles={{
                input: {
                  fontSize: "14px",
                },
              }}
              error={errors?.email?.message}
            />

            <Box className="w-full flex items-start gap-3">
              <Box className="w-auto flex-col flex items-center border rounded py-[3px]">
                <PhoneInput
                  country={"ng"}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  inputStyle={{
                    border: "none",
                    width: "100px",
                  }}
                  containerStyle={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                  }}
                  onChange={(value) => methods.setValue("countryCode", value)}
                />
                {/* {errors?.countryCode?.message && (
                <div className="text-red-600 text-xs mt-1">
                  {errors.countryCode.message}
                </div>
              )} */}
              </Box>
              <Box className="w-full">
                <TextInput
                  size="md"
                  placeholder="Phone number"
                  type="number"
                  styles={{
                    input: {
                      fontSize: "14px",
                    },
                  }}
                  {...methods.register("phone")}
                  error={errors?.phone?.message}
                />
              </Box>
            </Box>

            <PasswordInput
              placeholder="Password"
              className="w-full h-full"
              size="md"
              leftSection={<img src={PadLockIcon} alt="padlock-icon" />}
              styles={{
                input: {
                  fontSize: "14px",
                },
              }}
              // name="password"
              {...methods.register("password")}
              error={errors?.password?.message}
            />
            <TextInput
              size="md"
              placeholder="Postcode"
              leftSection={<img src={PostCodeIcon} alt="postcode-icon" />}
              className=" text-xs font-normal"
              styles={{
                input: {
                  fontSize: "14px",
                },
              }}
              // name="post_code"
              {...methods.register("postCode")}
              error={errors?.postCode?.message}

              // error
            />
            <Box>
              <Checkbox
                label="I accept the terms and agreements"
                size="xs"
                {...methods.register("terms")}

                // name=""
              />
              {errors?.terms && (
                <span className="text-[#FA5252] text-sm mt-1">
                  {errors.terms.message}{" "}
                </span>
              )}
            </Box>
            <Button
              size="md"
              className="mt-4"
              type="submit"
              loading={isLoading}
            >
              Sign up
            </Button>
          </form>
        </FormProvider>
        <div className="w-full flex justify-center mt-3">
          <span className=" text-sm">
            Already have an account?{" "}
            <span
              onClick={handleOpenLoginForm}
              className=" text-[#4188F9] cursor-pointer"
            >
              Login
            </span>
          </span>
        </div>
      </RegistrationCard>
      <div>
        <ConfirmPassword
          openConfirmPassword={openConfirmPassword}
          setOpenConfirmPassword={setOpenConfirmPassword}
          otpMethod={methods}
          onSubmit={onSubmit}
          phone={phone}
          handleSignUp={setOpenSignUp}
          resendOTP={emailMutate}
          isLoading={isFetching || isLoading}
          requestOTPMethod={otpRequestMethod}
        />
      </div>
    </>
  );
};
