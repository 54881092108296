import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router,  } from "pages/Router/Router";
import { Provider } from "react-redux";
import { AuthProvider } from "shared/provider/authProvider";
// import AuthGuard from "shared/authGuard";
import { NotificationContainer, QueryClientProviderWrapper } from "shared";
import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import HydrationWrapper from "shared/hydrationWrapper/hydrationWrapper";
import { LayoutProvider } from "context/LayoutContext";
import { RoutesWrapper } from "pages";
// import { store } from "redux";

function App() {
  const theme = createTheme({
    fontFamily: "Open Sans, sans-serif",
    primaryColor: "blue",
  });
  return (
    // <Provider store={store}>
    <QueryClientProviderWrapper>
      <MantineProvider theme={theme} withCssVariables>
        {/* <Notifications
          style={{ width: "300px" }}
          position="top-right"
          zIndex={6000}
        /> */}
        {/* <HydrationWrapper> */}
        <BrowserRouter>
          <RoutesWrapper />
          {/* <Notifications
              style={{ width: "300px" }}
              position="top-right"
              zIndex={6000}
            /> */}
          <NotificationContainer />
        </BrowserRouter>
        {/* </HydrationWrapper> */}
      </MantineProvider>
    </QueryClientProviderWrapper>

		// </Provider>
	);
}

export default App;
