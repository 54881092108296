import React from "react";
import { Faq, FaqContainer, FaqFlexbox, FaqsBox } from "./FAQStyles";
import { BlogHeader, DashedTitle } from "../Blog/BlogStyles";
import { BsDashLg } from "react-icons/bs";
import { Div, Input, Label } from "assets";


export const FAQ = () => {
  return (
    <FaqContainer>
      <DashedTitle>
        <BsDashLg /> FREQUENTLY ASKED QUESTIONS
      </DashedTitle>
      <FaqFlexbox>
        <BlogHeader>Got Questions? We've Got Answers!</BlogHeader>
        <FaqsBox>
          <Faq>
            <Input type="checkbox" id="first" name="accordion" />
            <Label htmlFor="first">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            </Label>
            <Div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur minima accusamus ea laudantium unde optio recusandae
              provident illum!
            </Div>
          </Faq>

          <Faq>
            <Input type="checkbox" id="second" name="accordion" />
            <Label htmlFor="second">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            </Label>
            <Div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur minima accusamus ea laudantium unde optio recusandae
              provident illum!
            </Div>
          </Faq>

          <Faq>
            <Input type="checkbox" id="third" name="accordion" />
            <Label htmlFor="third">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            </Label>
            <Div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur minima accusamus ea laudantium unde optio recusandae
              provident illum!
            </Div>
          </Faq>
        </FaqsBox>
      </FaqFlexbox>
    </FaqContainer>
  );
};

export default FAQ;
