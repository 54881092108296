import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { getUserProfile } from "services/authServices/services";
import { getDecodedJwt, isAuthenticated } from "utils/auth/auth";
import { IDecodedUser } from "utils/auth/auth.type";

export const useAuthenticatedUser = () => {
  const decodedUser: IDecodedUser = getDecodedJwt();
  const queryClient = useQueryClient();
  const userId = decodedUser.user_id;
  const { isLoading, data } = useQuery(
    {
      queryKey: ["user-profile", { userId }],
      queryFn: async () => await getUserProfile(),
      enabled: isAuthenticated() && !!userId!,
    },
    queryClient
  );

  return { isLoading, userDetails: data };
};

export default useAuthenticatedUser;
