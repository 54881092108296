import React from 'react'
import { OtherServicesContainer, OtherServicesFlexBox } from './OtherServiceStyles'
import {Div, Paragraph} from "assets"

export const OtherServices = () => {
  return (
    <OtherServicesContainer>
        <Paragraph>
            You might be interested in some other services
        </Paragraph>

        <OtherServicesFlexBox>
            {
                Array.from({ length: 32}, (_, index) => (
                    <Div>
                        Tag name here
                    </Div>
                ))
            }
            <Div>
                Tag name here
            </Div>
        </OtherServicesFlexBox>
    </OtherServicesContainer>
  )
}


