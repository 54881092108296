interface Route {
  name: string;
  path: string;
}

export const routes: Record<string, Route> = {
  HOME: {
    name: "home",
    path: "/",
  },

  SEARCH: {
    name: "search",
    path: "/search",
  },
  // LOGIN: {
  //   name: "login",
  //   path: "/login",
  // },
  // SIGNUP: {
  //   name: "sign-up",
  //   path: "/sign-up",
  // },
  // FORGOTPASSWORD: {
  //   name: "forgot-password",
  //   path: "/forgot-password",
  // },
  // Add other routes here
};
