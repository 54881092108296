import { themeColors } from "Themes/ThemeColors";
import { styled } from "styled-components";

export const NavbarContainer = styled.div``;

export const NavbarTop = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 4rem 1rem 4rem;
`;

export const NavbarBottom = styled.div`
  display: flex;
  margin: 1rem 4rem 1rem 4rem;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const NavbarTopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    font-weight: 500;
    font-size: 0.9rem;
  }
`;

export const NavbarLogo = styled.img`
  cursor: pointer;
`;

export const NavbarTopLeftMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 3rem;
`;

export const NavbarTopLeftMenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
`;
export const NavbarTopRightMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const NavbarTopRightMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const NavbarSignInButton = styled.button`
  background-color: ${themeColors.navbarBtnBlue};
  color: ${themeColors.white};
  padding: 0.5rem 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NavbarBrowseButton = styled.button`
  background-color: ${themeColors.navbarBtnBlue};
  color: ${themeColors.white};
  padding: 0.7rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ProviderText = styled.p`
  font-weight: 600;
  border-bottom: 2px solid ${themeColors.grey};
  font-size: 13px;
`;

export const NavbarHr = styled.hr`
  border-bottom: 1px solid ${themeColors.grey};
`;
