import { Button, PasswordInput, TextInput } from "@mantine/core";
import { PadLockIcon, ProfileIcon } from "assets/svg";
import { RegistrationCard } from "component/common/Layout/registrationCard";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

export const AddNewPassword = ({
  openNewPassword,
  methods,
  onSubmit,
  isLoading,
}: // handleOpenForgetPassword,

{
  openNewPassword: boolean;
  methods: any;
  onSubmit: (v: any) => void;
  isLoading: boolean;
  // handleOpenForgetPassword: () => void;
}) => {
  const { errors } = methods.formState;

  return (
    <RegistrationCard
      show={openNewPassword}
      // onClose={handleOpenForgetPassword}
    >
      <div className="w-full flex justify-center">
        <span className="text-xl font-bold">Forget Password</span>
      </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-5 mt-6 w-full"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <PasswordInput
            placeholder="Enter your password..."
            label="Password"
            className="w-full"
            styles={{
              input: {
                fontSize: "14px",
              },
            }}
            size="md"
            leftSection={<img src={PadLockIcon} />}
            {...methods.register("password")}
            error={errors?.password?.message}
          />

          <PasswordInput
            placeholder="Enter your password..."
            label="Confirm Password"
            className="w-full"
            styles={{
              input: {
                fontSize: "14px",
              },
            }}
            size="md"
            leftSection={<img src={PadLockIcon} />}
            {...methods.register("passwordConfirmation")}
            error={errors?.passwordConfirmation?.message}
          />

          <Button size="md" type="submit" loading={isLoading}>
            Continue
          </Button>
        </form>
      </FormProvider>
    </RegistrationCard>
  );
};
