import React from "react";
import {
  GetQuoteButton,
  HeroCallStyledText,
  HeroCallText,
  HeroContainer,
  HeroLeft,
  HeroMainWorker,
  HeroMan,
  HeroPopularSearch,
  HeroPopularSearchItem,
  HeroQuoteBtnContainer,
  HeroRight,
  HeroServiceSearch,
  HeroServiceSearchBtn,
  HeroServiceSearchInput,
  HeroServiceSearchLocation,
  HeroTrustPilot,
  HeroWorkerBg,
  HeroWorkerBtn,
  HeroWorkerInfo,
  HeroWorkerInfoContainer,
  HeroWorkerRating,
  HeroWorkerTop,
  HeroWorkersBox,
  ScrollLeft,
  ScrollRight,
} from "./HeroStyles";
import {
  Div,
  Image,
  Input,
  Paragraph,
  heroMan,
  heroTextUnderline,
  heroWorkerBg,
  trustPilot,
  worker,
} from "assets";
import { MdArrowRightAlt, MdLocationOn, MdSearch } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";

export const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroLeft>
        <HeroQuoteBtnContainer>
          <GetQuoteButton>
            Get Free Quotes Within Minutes&nbsp; <MdArrowRightAlt />{" "}
          </GetQuoteButton>
        </HeroQuoteBtnContainer>

        <HeroCallText>
          <Paragraph>
            We Take Care Of Your Home & Your&nbsp;
            <HeroCallStyledText>
              <Paragraph> Freelance </Paragraph>
              <Image src={heroTextUnderline} />{" "}
            </HeroCallStyledText>
            Projects
          </Paragraph>
        </HeroCallText>

        <HeroServiceSearch>
          <HeroServiceSearchLocation>
            <MdLocationOn />
            <Paragraph>Location</Paragraph>
          </HeroServiceSearchLocation>
          <HeroServiceSearchInput>
            <Input placeholder="What service are you looking for today?" />
            <HeroServiceSearchBtn>
              <MdSearch style={{ fontSize: "1.5rem" }} />
            </HeroServiceSearchBtn>
          </HeroServiceSearchInput>
        </HeroServiceSearch>

        <HeroPopularSearch>
          <Paragraph>Popular Searches:</Paragraph>
          <Div>
            {Array.from({ length: 5 }, (_, index) => (
              <HeroPopularSearchItem>Web Design</HeroPopularSearchItem>
            ))}
          </Div>
        </HeroPopularSearch>

        <HeroTrustPilot>
          <Div>
            <Image src={trustPilot} />
            TrustPilot
          </Div>
          <Paragraph>Verify By Trust Pilot</Paragraph>
        </HeroTrustPilot>
      </HeroLeft>

      <HeroRight>
        <HeroMainWorker>
          <HeroWorkerBg src={heroWorkerBg} />
          <HeroMan src={heroMan} />
          <HeroWorkerTop>Bring me your challenges</HeroWorkerTop>
          <HeroWorkerInfoContainer>
            <Div>
              <HeroWorkerInfo>Usman Zafar</HeroWorkerInfo>
              <HeroWorkerRating>
                <RiVerifiedBadgeFill />
                Top rated Carpenter
              </HeroWorkerRating>
            </Div>
            <HeroWorkerBtn>Hire me</HeroWorkerBtn>
          </HeroWorkerInfoContainer>
        </HeroMainWorker>

        <ScrollLeft>
          <MdChevronLeft />
        </ScrollLeft>
        <ScrollRight>
          <MdChevronRight />
        </ScrollRight>

        <HeroWorkersBox>
          {Array.from({ length: 5 }, (_, index) => (
            <Image src={worker} />
          ))}
        </HeroWorkersBox>
      </HeroRight>
    </HeroContainer>
  );
};
