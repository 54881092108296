import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider, createTheme } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
// import { Open_Sans } from 'next/font/google';
// import "@mantine/core/styles.css";
// import "@mantine/dates/styles.css";
// import "@mantine/tiptap/styles.css";
// import "@mantine/carousel/styles.css";
// import "@mantine/notifications/styles.css";
// import "mantine-datatable/styles.layer.css";
// import "./globals.css";
import "@mantine/core/styles.css";

import { ActionIcon, Loader } from "@mantine/core";
import HydrationWrapper from "shared/hydrationWrapper/hydrationWrapper";
// import NotificationContainer from "shared/provider/notification/notificationProvider";

export const myTheme = createTheme({
  primaryColor: "#4188F9",
  defaultRadius: "md",
  focusRing: "always",
  // fontFamily: 'Manrope, sans-serif',
  // headings: { fontFamily: 'Manrope, sans-serif' },
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: "subtle",
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        type: "bars",
      },
    }),
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = createTheme({
  fontFamily: "Open Sans, sans-serif",
  primaryColor: "blue",
});
root.render(
  <React.StrictMode>
    {/* <MantineProvider theme={theme} withCssVariables>
      <Notifications position="top-right" zIndex={3000} /> */}
    {/* <ModalsProvider>{children}</ModalsProvider> */}
    <MantineProvider theme={theme} withCssVariables>
      <HydrationWrapper>
        {/* <Notifications
          style={{ width: "300px", height: "100%" }}
          position="bottom-right"
          zIndex={6000}
        /> */}
        {/* <NotificationContainer > */}
        <App />
        {/* </NotificationContainer> */}
      </HydrationWrapper>
    </MantineProvider>
    {/* </MantineProvider> */}
  </React.StrictMode>
);

reportWebVitals();
