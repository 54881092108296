// import logo1 from "../../../../assets/images/logo1.png";
// import logo2 from "../../../../assets/images/logo2.png";
// import logo3 from "../../../../assets/images/logo3.png";
// import logo4 from "../../../../assets/images/logo4.png";
// import logo5 from "../../../../assets/images/logo5.png";
// import logo6 from "../../../../assets/images/logo6.png";
import {
  CarouselContainer,
  Logo,
  LogosContainer,
  LogosCarouselDiv,
} from "./LogosCarouselStyles";
import { Paragraph, logo1, logo2, logo3, logo4, logo5, logo6 } from "assets";

export const LogosCarousel = () => {
  return (
    <LogosCarouselDiv>
        <Paragraph>
            Trusted by 180,000+ customers worldwide
        </Paragraph>
      <CarouselContainer>
        <LogosContainer>
          <Logo src={logo1} />
          <Logo src={logo2} />
          <Logo src={logo3} />
          <Logo src={logo4} />
          <Logo src={logo5} />
          <Logo src={logo6} />
        </LogosContainer>
        <LogosContainer>
          <Logo src={logo1} />
          <Logo src={logo2} />
          <Logo src={logo3} />
          <Logo src={logo4} />
          <Logo src={logo5} />
          <Logo src={logo6} />
        </LogosContainer>
      </CarouselContainer>
    </LogosCarouselDiv>
  );
};


