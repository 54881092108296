import { Button, PinInput } from "@mantine/core";
import { themeColors } from "Themes/ThemeColors";
import { RegistrationCard } from "component/common/Layout/registrationCard";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ICreateEmailType } from "services/authServices/services";

export const ConfirmPassword = ({
  openConfirmPassword,
  setOpenConfirmPassword,
  otpMethod,
  onSubmit,
  phone,
  handleSignUp,
  resendOTP,
  isLoading,
  requestOTPMethod,
  type,
}: {
  openConfirmPassword: boolean;
  setOpenConfirmPassword: (v: boolean) => void;
  otpMethod: any;
  onSubmit: (v: any) => void;
  phone?: string;
  handleSignUp: (v: boolean) => void;
  resendOTP: any;
  isLoading: boolean;
  requestOTPMethod: any;
  type?: string;
}) => {
  // const [otpModal, setOtpModal] = useState<boolean>(false);
  const [timeSpan, setTimeSpan] = useState<number>(0);
  const [interval, setCountdown] = useState<boolean>(false);
  const [loadingOtp, setOtpLoading] = useState<boolean>(false);

  const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `in ${formattedMinutes}:${formattedSeconds}`;
  };

  const onSubmitEmail = (payload: ICreateEmailType) => {
    handleSignUp(false);

    if (timeSpan === 0) {
      setOtpLoading(true);
      setCountdown(!interval);
    }
    resendOTP({
      email: payload?.email,
    });
  };

  // const resendOtp = async () => {
  //   if (timeSpan === 0) {
  //     setOtpLoading(true);
  //     try {
  //       // if (res) {
  //       setCountdown(!interval);
  //       // }
  //     } catch (error) {
  //       // showNotification?.(handleApiError(error), {
  //       //   type: "error",
  //       // });
  //     }
  //   }
  //   setOtpLoading(false);
  // };

  useEffect(() => {
    let timerInterval: any;
    if (openConfirmPassword) {
      const startTime = Date.now();
      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + 2);

      const calculateTimeSpan = () => {
        const remainingTime = endTime.getTime() - Date.now();
        setTimeSpan(() => Math.max(0, remainingTime));
      };

      calculateTimeSpan();

      timerInterval = setInterval(() => {
        calculateTimeSpan();
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [interval, openConfirmPassword]);

  return (
    <RegistrationCard show={openConfirmPassword}>
      <div className="w-full flex flex-col  items-center gap-2">
        <span className="text-xl font-bold">Confirm your Email Address</span>
        <span className="text-center text-sm">
          We have sent an OTP to your email,
          {/* to your number {phone?.slice(0, 2)}**********
          {phone?.slice(phone?.length - 2, phone?.length)}{" "} */}
          {type !== "forgot-password" && (
            <>
              <span> if you wish to change your email, </span>{" "}
              <span
                onClick={() => {
                  setOpenConfirmPassword(false);
                  handleSignUp(true);
                }}
                className={` cursor-pointer underline text-[${themeColors.navbarBtnBlue}]`}
              >
                {" "}
                Click here
              </span>
            </>
          )}
        </span>
      </div>
      <form
        className="mt-6 w-full items-center flex flex-col "
        onSubmit={
          type === "forgot-password"
            ? timeSpan === 0
              ? requestOTPMethod.handleSubmit(onSubmitEmail)
              : otpMethod.handleSubmit(onSubmit)
            : otpMethod.handleSubmit(timeSpan === 0 ? onSubmitEmail : onSubmit)
        }
      >
        <PinInput
          size="md"
          length={6}
          type="number"
          onChange={(value) => otpMethod.setValue("otp", value)}
          // {...otpMethod.register("otp")}
        />
        <span className="text-sm my-3">
          Didn’t receive a code? Resend {timeSpan !== 0 && formatTime(timeSpan)}
        </span>
        {/* 
        <Box my={10}>
              <LoadingButton variant="outlined" disabled={timeSpan !== 0} onClick={resendOtp}>
                Resend OTP {timeSpan !== 0 && formatTime(timeSpan)}
              </LoadingButton>
            </Box> */}
        <Button
          size="md"
          className="mt-5 w-full font-normal"
          color={themeColors.navbarBtnBlue}
          style={{ width: "100%" }}
          // onClick={() => {
          //   setOpenConfirmPassword(false);
          // }}
          disabled={
            timeSpan === 0 ? false : !(otpMethod.watch("otp")?.length === 6)
          }
          type="submit"
          loading={isLoading}
        >
          {timeSpan === 0
            ? "Rsend OTP Verification"
            : "Confirm OTP Verification"}
        </Button>
      </form>
    </RegistrationCard>
  );
};
