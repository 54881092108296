import { themeColors } from "Themes/ThemeColors";
import styled from "styled-components";

export const ExploreContainer = styled.div`
    padding: 0 3rem;
    margin-top: 4rem;
`

export const ExploreHeader = styled.p`
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    p {
        color: ${themeColors.navbarBtnBlue};
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
`

export const ExploreServicesContainer = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
row-gap: 3rem;
`

export const Service = styled.div`
`

export const ExploreImg = styled.img`
    margin-bottom: 1rem;
`

export const SubServiceHeader = styled.p`
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: .5rem;
`

export const SubServiceItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5rem;

    p {
        color: ${themeColors.footerHeaderGrey};
    }
`
