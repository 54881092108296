import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export function QueryClientProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  // const [client] = useState(
  //   () =>
  //     new QueryClient({
  //       defaultOptions: {
  //         queries: {
  //           retry: 1,
  //         },
  //       },
  //     })
  // );
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { retry: 2 },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} position="top" /> */}
    </QueryClientProvider>
  );
}
